import React from 'react';
import LoginContainer from './Auth/Login/components/LoginContainer/LoginContainer';
import { Typography } from '@mui/material';
import HeaderButton from 'components/Buttons/HeaderButton';

type Props = {
  messageOverride?: string;
};

const NotFound = ({ messageOverride }: Props) => {
  const defaultMessage = `Sorry, we couldn't find the page you were looking for. Please try again or contact ReStore support.`;

  return (
    <LoginContainer>
      <Typography variant='h3' sx={{ marginBottom: '1.5rem' }}>
        Something went wrong!
      </Typography>
      <Typography variant='body2' sx={{ marginBottom: '1.5rem', textAlign: 'center' }}>
        {messageOverride ?? defaultMessage}
      </Typography>
      <HeaderButton to='/'>Back to ReStore</HeaderButton>
    </LoginContainer>
  );
};

export default NotFound;
