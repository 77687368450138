import { gql } from '__generated__/gql';

export const REFRESH_COGNITO_TOKENS = gql(/* GraphQL */ `
  mutation RefreshCognitoTokens($input: RefreshCognitoTokensInput!) {
    refreshCognitoTokens(input: $input) {
      success
      error
      data {
        caslAbilities
        tokens {
          id_token
          access_token
          refresh_token
          expires_at
          token_type
        }
        user {
          id
          firstName
          lastName
          email
          phoneNumber
          emailNotifications
          mobilePushNotifications
          tenant {
            id
            name
            dateFormat
            timezone
          }
          cognitoUsername
          isActive
          userGroups {
            id
            name
            cognitoGroupName
            displayColour
          }
          tags {
            id
            ownerId
            name
            displayColour
            archived
          }
          brands {
            id
            name
            archived
          }
        }
      }
    }
  }
`);
