import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useEffect,
  useState,
  useMemo,
  useRef,
} from 'react';
import { Tenant, User } from '__generated__/graphql';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import { DateTime } from 'luxon';
import { LOGIN_LANDING_PAGE } from 'const/routes';
import {
  AppAbilityInterface,
  generateAbility,
  newAbility,
  hasPermissions,
  PermissionAction,
  Condition,
} from 'config/ability';
import { AbilityContext } from 'components/Abilities/Can';
import { apolloClient } from 'config/apolloClient';
import useUserAnalyticsIdsRegistration from 'hooks/analytics/useUserAnalyticsIdsRegistration';
import useRefreshCognitoTokensMutation from '../hooks/auth/mutations/useRefreshCognitoTokensMutation';

export interface UserInterface {
  caslAbilities?: string;
  user: User;
  tokens: {
    id_token?: string | null;
    access_token?: string | null;
    refresh_token?: string | null;
  };
}

interface AuthContextInterface {
  user?: UserInterface;
  setUser: (user: UserInterface) => void;
  login: (
    user: UserInterface,
    loginDestination?: string,
    replace?: boolean,
    delay?: boolean
  ) => void;
  userTenantId?: number;
  userTenantName?: string;
  userTenantTimezone?: string;
  userTenantOnLogin?: Tenant;
  setUserTenant: (tenant?: Tenant) => void;
  loading: boolean;
  isSessionActive: () => boolean;
  logout: (doNavigate?: boolean) => void;
  ability: AppAbilityInterface;
  hasPermission: (
    action: string | PermissionAction,
    subject: string,
    conditions?: Condition | Condition[]
  ) => boolean;
  isSuperAdmin: boolean;
  lastTenantId: number | null;
  lastEmail: string | null;
}

const defaultContext: AuthContextInterface = {
  setUser: () => {},
  setUserTenant: () => {},
  login: () => {},
  loading: true,
  isSessionActive: () => true,
  logout: () => {},
  ability: newAbility(),
  hasPermission: () => false,
  isSuperAdmin: false,
  lastTenantId: null,
  lastEmail: null,
};

const AuthContext = createContext<AuthContextInterface>(defaultContext);

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [user, setUser] = useState<UserInterface>();
  const [userTenantId, setUserTenantId] = useState<number | undefined>(user?.user.tenant?.id);
  const [userTenantName, setUserTenantName] = useState<string | undefined>(user?.user.tenant?.name);
  const [userTenantTimezone, setUserTenantTimezone] = useState<string | undefined>(
    user?.user.tenant?.timezone
  );
  const [userTenantOnLogin, setUserTenantOnLogin] = useState<Tenant | undefined>(
    user?.user.tenant ?? undefined
  );
  const navigate = useNavigate();
  const [ability, setAbility] = useState<AppAbilityInterface>(newAbility());
  const [loading, setLoading] = useState<boolean>(true);

  //this will be used as a mutex to only allow one instance of refreshSession() to run at a time
  //it's a ref instead of state so that it doesn't trigger additional renders
  const refreshing = useRef<boolean>(false);

  const [refreshMutation] = useRefreshCognitoTokensMutation();

  // Push user properties to analytics.
  useUserAnalyticsIdsRegistration(user);

  const lastTenantId = useMemo(() => {
    const storageTenant = localStorage.getItem('restore.user.lastTenantId');
    return userTenantId ?? (storageTenant ? Number(storageTenant) : null);
  }, [userTenantId]);

  const lastEmail = useMemo(() => {
    const storageEmail = localStorage.getItem('restore.user.lastEmail');
    return user?.user.email ?? storageEmail ?? null;
  }, [user?.user.email]);

  /**
   * Checks permissions on a set of conditions
   */
  const hasPermission = useCallback(
    (
      action: string | PermissionAction,
      subject: string,
      conditions?: object | object[]
    ): boolean => {
      if (Array.isArray(conditions)) {
        return (conditions as Array<object>).reduce((acc, condition) => {
          return acc || hasPermissions(ability, action, subject, condition);
        }, false);
      }

      return hasPermissions(ability, action, subject, conditions as object);
    },
    [ability]
  );

  const isSuperAdmin = useMemo(
    () => hasPermission(PermissionAction.Access, 'SuperTenant'),
    [hasPermission]
  );

  const updateUserTenant = (newTenant?: Tenant) => {
    if (newTenant) {
      localStorage.setItem('restore.user.lastViewedTenant', JSON.stringify(newTenant));
      setUserTenantId(newTenant.id);
      setUserTenantName(newTenant.name);
      setUserTenantTimezone(newTenant.timezone);
    }
  };

  const updateUserTenantOnLogin = (newTenant?: Tenant) => {
    if (newTenant) {
      localStorage.setItem('restore.user.originalTenant', JSON.stringify(newTenant));
      setUserTenantOnLogin(newTenant);
    }
  };

  const restoreTenantFromLocalStorage = (localStorageUser: UserInterface) => {
    const localStorageLastViewedTenant = localStorage.getItem('restore.user.lastViewedTenant');
    if (localStorageLastViewedTenant !== null) {
      localStorageUser.user.tenant = JSON.parse(localStorageLastViewedTenant);
      setUserTenantId(localStorageUser.user.tenant?.id);
      setUserTenantName(localStorageUser.user.tenant?.name);
      setUserTenantTimezone(localStorageUser.user.tenant?.timezone);
    }

    const localStorageUserTenantOnLogin = localStorage.getItem('restore.user.originalTenant');
    if (localStorageUserTenantOnLogin !== null) {
      setUserTenantOnLogin(JSON.parse(localStorageUserTenantOnLogin));
    }
  };

  const logout = useCallback(
    (doNavigate = true) => {
      if (localStorage.getItem('restore.user')) {
        localStorage.removeItem('restore.user');
        localStorage.removeItem('restore.user.lastViewedTenant');
        localStorage.removeItem('restore.user.originalTenant');
      }
      setUser(undefined);
      setUserTenantId(undefined);
      setAbility(newAbility());
      setLoading(false);
      apolloClient.clearStore();
      if (doNavigate) {
        navigate('/login');
      }
    },
    [navigate]
  );

  const login = useCallback(
    (user: UserInterface, loginDestination?: string, replace?: boolean, delay = false) => {
      apolloClient.clearStore();
      localStorage.setItem('restore.user', JSON.stringify(user));
      localStorage.setItem('restore.user.lastTenantId', user.user.tenant?.id.toString() ?? '');
      localStorage.setItem('restore.user.lastEmail', user.user.email ?? '');
      updateUserTenantOnLogin(user.user.tenant ?? undefined);
      setUser(user);
      setUserTenantId(user?.user.tenant?.id);
      setUserTenantName(user?.user.tenant?.name);
      setUserTenantTimezone(user?.user.tenant?.timezone);
      setAbility(generateAbility(user));

      //Give time for updated state to settle. When logging in via the tenancy switcher, the user will
      //otherwise be redirected to the dashboard before state is fully updated, and will load the previous
      //tenancys project list filters
      setTimeout(
        () => navigate(loginDestination ?? LOGIN_LANDING_PAGE, replace ? { replace } : undefined),
        delay ? 50 : 0
      );

      setLoading(false);
    },
    [navigate]
  );

  const getCurrentTokens = useCallback(() => {
    const localStorageUserJson = localStorage.getItem('restore.user');
    const localStorageUser = JSON.parse(localStorageUserJson ?? 'null');
    return {
      access_token: localStorageUser?.tokens.access_token ?? '',
      refresh_token: localStorageUser?.tokens.refresh_token ?? '',
    };
  }, []);

  const refreshSession = useCallback(async () => {
    //if user isn't set, we can't refresh the token. this can happen when the
    //page is refreshed with an expired token. Once state settles we can refresh
    if (refreshing.current || user === undefined) {
      return;
    }

    refreshing.current = true;
    setLoading(true);
    const { refresh_token } = getCurrentTokens();

    //we can't trust the tenant id in user, because if the user admin tenancy switches
    //it will be the switched-to tenant which is not where their account is
    const tenantId = userTenantOnLogin?.id ?? lastTenantId ?? 0;

    try {
      const result = await refreshMutation({
        variables: {
          input: {
            tenantId,
            userEmail: user?.user.cognitoUsername ?? '',
            refreshToken: refresh_token,
          },
        },
      });

      if (result.data?.refreshCognitoTokens.success && result.data.refreshCognitoTokens.data) {
        const newUser = result.data.refreshCognitoTokens.data;
        //the response doesn't include a refresh token (will always be null),
        //so we'll reuse the one we already had
        newUser.tokens.refresh_token = refresh_token;
        setUser(newUser);
        setAbility(generateAbility(newUser));
        localStorage.setItem('restore.user', JSON.stringify(result.data.refreshCognitoTokens.data));
      } else {
        logout();
      }
    } catch (ex) {
      console.warn('Caught exception refreshing token', ex);
      logout();
    } finally {
      setLoading(false);
      refreshing.current = false;
    }
  }, [getCurrentTokens, userTenantOnLogin, lastTenantId, refreshMutation, logout, user]);

  const isTokenValid = useCallback((token: string) => {
    const decodedToken: { exp: number } = token ? jwt_decode(token) : { exp: -1 };
    const expireTime = Number(decodedToken.exp) * 1000;

    return decodedToken && expireTime > DateTime.now().toMillis();
  }, []);

  const isRefreshValid = useCallback((refreshToken: string, accessToken: string) => {
    const decodedToken: { iat: number } = accessToken ? jwt_decode(accessToken) : { iat: -1 };
    //the refresh token should be valid for 30 days after the access token was issued
    //NOTE: this is a cognito setting and is subject to change
    const stillValid = DateTime.fromSeconds(decodedToken.iat).plus({ days: 30 }) > DateTime.now();
    return !!refreshToken && stillValid;
  }, []);

  const isSessionActive = useCallback(() => {
    const { access_token, refresh_token } = getCurrentTokens();

    const tokenValid = isTokenValid(access_token);
    const refreshValid = isRefreshValid(refresh_token, access_token);

    if (refreshValid && !tokenValid) {
      // if the access token is expired but the refresh token is valid,
      // we need to kick off the refresh process.
      refreshSession();
    }

    return tokenValid || refreshValid;
  }, [getCurrentTokens, isTokenValid, isRefreshValid, refreshSession]);

  /**
   * refreshes the session when the page is reloaded
   */
  useEffect(() => {
    const sessionActive = isSessionActive();
    if (loading && !user && sessionActive) {
      const localStorageUserJson = localStorage.getItem('restore.user');
      const localStorageUser: UserInterface =
        localStorageUserJson && JSON.parse(localStorageUserJson);
      const abilities = generateAbility(localStorageUser);
      setUser(localStorageUser);
      setUserTenantId(localStorageUser?.user.tenant?.id);
      setAbility(abilities);
      setUserTenantName(localStorageUser.user.tenant?.name);
      setUserTenantTimezone(localStorageUser.user.tenant?.timezone);

      if (abilities.can('access', 'SuperTenant')) {
        restoreTenantFromLocalStorage(localStorageUser);
      }
    }

    const tokenValid = isTokenValid(getCurrentTokens().access_token);
    const refreshValid = isRefreshValid(
      getCurrentTokens().refresh_token,
      getCurrentTokens().access_token
    );
    //don't clear loading if we're trying to refresh
    if (tokenValid || !refreshValid) {
      setLoading(false);
    }
  }, [getCurrentTokens, isRefreshValid, isSessionActive, isTokenValid, loading, logout, user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        setUser,
        userTenantId,
        userTenantName,
        userTenantTimezone,
        userTenantOnLogin,
        setUserTenant: updateUserTenant,
        login,
        loading,
        isSessionActive,
        logout,
        ability,
        hasPermission,
        isSuperAdmin,
        lastTenantId,
        lastEmail,
      }}
    >
      <AbilityContext.Provider value={ability}>{children}</AbilityContext.Provider>
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
