import React from 'react';
import { Button, ButtonProps } from '@mui/material';
import { Link, LinkProps } from 'react-router-dom';

interface HeaderButtonProps extends ButtonProps {
  to?: string;
  component?: React.ForwardRefExoticComponent<LinkProps & React.RefAttributes<HTMLAnchorElement>>;
}

export const HeaderButton = ({
  children,
  onClick,
  to,
  disabled = false,
  variant,
  color,
  ...otherProps
}: HeaderButtonProps) => {
  const buttonProps: HeaderButtonProps = {
    variant: variant ?? 'contained',
    color: color ?? 'primary',
    disabled,
  };

  if (onClick) {
    buttonProps.onClick = onClick;
  }

  if (to && !disabled) {
    buttonProps.component = Link;
    buttonProps.to = to;
  }

  return (
    <Button sx={{ whiteSpace: 'nowrap' }} {...otherProps} {...buttonProps}>
      {children}
    </Button>
  );
};

export default HeaderButton;
