import React, { createContext, ReactNode, useContext, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { useMediaQuery } from '@mui/material';

interface ClientContextInterface {
  drawerWidth: number;
  sm: boolean;
  md: boolean;
  lg: boolean;
  xl: boolean;
  showSidebar: boolean;
  isMobile: boolean;
  toggleShowSidebar: () => void;
  sidebarCampaignName?: string;
  setSidebarCampaignName: (name: string) => void;
}

export const drawerWidthRem = 15; // 240px

const defaultContext: ClientContextInterface = {
  drawerWidth: drawerWidthRem,
  sm: true,
  md: true,
  lg: true,
  xl: true,
  showSidebar: true,
  isMobile: false,
  toggleShowSidebar: () => {},
  sidebarCampaignName: undefined,
  setSidebarCampaignName: () => {},
};

const ClientContext = createContext<ClientContextInterface>(defaultContext);

export const ClientContextProvider = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.up('sm'));
  const md = useMediaQuery(theme.breakpoints.up('md'));
  const lg = useMediaQuery(theme.breakpoints.up('lg'));
  const xl = useMediaQuery(theme.breakpoints.up('xl'));
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [showSidebar, setShowSidebar] = useState<boolean>(md);
  const [sidebarCampaignName, setSidebarCampaignName] = useState<string>();
  const [drawerWidth] = useState<number>(drawerWidthRem);

  window.onresize = () => {
    setShowSidebar(md);
  };

  const toggleShowSidebar = () => {
    setShowSidebar(!showSidebar);
  };

  return (
    <ClientContext.Provider
      value={{
        drawerWidth,
        sm,
        md,
        lg,
        xl,
        showSidebar,
        isMobile,
        toggleShowSidebar,
        sidebarCampaignName,
        setSidebarCampaignName,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export const useClientContext = () => useContext(ClientContext);
