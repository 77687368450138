import React, { ReactNode } from 'react';
import { css, styled } from '@mui/material/styles';
import { Box, CssBaseline, Container } from '@mui/material';
import imgReStoreVertical from 'assets/images/restore-logo-vertical.png';

const PageWrapper = styled(Box)(
  ({ theme }) => css`
    display: flex;
    min-height: 100vh;
    justify-content: center;
    align-items: center;
    background: ${theme.colors.neutral400};
  `
);

const StyledBox = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginPanel = styled(Box)`
  padding: 2.5rem;
  border-radius: 1.5rem;
  margin: 2.5rem 0;
  background: #ffffff;
  box-shadow: rgba(0, 0, 0, 0) 0 0 0 0, rgba(0, 0, 0, 0) 0 0 0 0,
    rgba(0, 0, 0, 0.1) 0 0.25rem 0.375rem -0.063rem, rgba(0, 0, 0, 0.1) 0 0.125rem 0.25rem -0.125rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const LoginContainer = ({ children }: { children: ReactNode }) => {
  return (
    <PageWrapper>
      <Container maxWidth='sm'>
        <CssBaseline />
        <StyledBox>
          <img src={imgReStoreVertical} alt={'ReStore Logo'} width={109} />
          <LoginPanel>{children}</LoginPanel>
        </StyledBox>
      </Container>
    </PageWrapper>
  );
};

export default LoginContainer;
