import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ApolloProvider } from '@apollo/client';
import { apolloClient } from 'config/apolloClient';
import trackUserProperties from './utils/analytics/trackUserProperties';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';

if (process.env.REACT_APP_SENTRY_DSN && !process.env.REACT_APP_SENTRY_DISABLED) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: Number(process.env.REACT_APP_SENTRY_TRACES_SAMPLE_RATE),
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  });
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <ApolloProvider client={apolloClient}>
      <App />
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

// Track user properties for analytics.
trackUserProperties();
