import { dataLayerPush } from 'utils/analytics/dataLayer';

export default async function trackUserProperties() {
  const publicIP = await getPublicIP();

  const userProperties = {
    user_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    user_ip_address: publicIP,
  };
  dataLayerPush({
    event: 'get_user_data',
    ...userProperties,
  });
}

async function getPublicIP() {
  try {
    const response = await fetch(`${process.env.REACT_APP_WEB_API}/api/settings/ip_address`, {
      mode: 'no-cors',
    });

    if (response.status === 200) {
      return response.text();
    }

    return '';
  } catch (e) {
    return '';
  }
}
