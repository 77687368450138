import {
  AbilityBuilder,
  AbilityClass,
  AbilityTuple,
  createMongoAbility,
  MongoAbility,
  MongoQuery,
  PureAbility,
  subject as conditionalSubject,
  SubjectRawRule,
  SubjectType,
} from '@casl/ability';
import { UserInterface } from 'context/auth.context';

export enum PermissionAction {
  Access = 'access',
  Complete = 'complete',
  Create = 'create',
  Delete = 'delete',
  Manage = 'manage',
  Read = 'read',
  Update = 'update',
}

export type TenantScopeCondition = { tenantId: number };
export type CampaignScopeCondition = { campaignId: number };
export type ActivityScopeCondition = { activityId: number };
export type StoreScopeCondition = { storeId: number };
export type CampaignStoreScopeCondition = { campaignStoreId: number };
export type ActivityAssignmentScopeCondition = { activityAssignmentId: number };
export type StepResponseScopeCondition = { stepResponseId: number };

export type Condition =
  | TenantScopeCondition
  | CampaignScopeCondition
  | ActivityScopeCondition
  | StoreScopeCondition
  | CampaignStoreScopeCondition
  | ActivityAssignmentScopeCondition
  | StepResponseScopeCondition
  | object;

type UserCaslRules = {
  rules?: AppRuleInterface[];
};

export type AppRuleInterface = SubjectRawRule<
  string,
  SubjectType,
  MongoQuery<Record<string | number | symbol, unknown>>
>;

export type AppAbilityInterface = MongoAbility<
  AbilityTuple,
  MongoQuery<Record<string | number | symbol, unknown>>
>;

export type CaslPermission = {
  action: string;
  subject: string;
  conditions?: Condition | Condition[];
};

export const AppAbility = PureAbility as AbilityClass<AppAbilityInterface>;

export const newAbility = () => {
  const { rules } = new AbilityBuilder(AppAbility);
  return new AppAbility(rules);
};

export const generateAbility = (user: UserInterface) => {
  const userRawRules: UserCaslRules =
    (user?.caslAbilities ? JSON.parse(user.caslAbilities) : {}) ?? {};
  return userRawRules.rules?.length ? createMongoAbility(userRawRules.rules) : newAbility();
};

export const hasPermissions = (
  ability: AppAbilityInterface,
  action: string,
  subject: string,
  conditions?: object
) => {
  // Check for SuperTenant permission
  if (ability.can('access', 'SuperTenant')) {
    return true;
  }

  if (conditions) {
    return ability.can(action, conditionalSubject(subject, { ...conditions }));
  }
  return ability.can(action, subject);
};
