import React, { ReactElement } from 'react';
import { Navigate, NonIndexRouteObject, RouteObject } from 'react-router-dom';
import { CaslPermission, PermissionAction } from 'config/ability';
import { LOGIN_LANDING_PAGE } from 'const/routes';
import { appChildPaths, AppPath } from 'utils/linkUtils';

export interface RouteInterface {
  path: string;
  shortcutPath?: string;
  element?: ReactElement;
  lazy?: any;
  showBreadcrumbs?: boolean;
  permission?: CaslPermission;
  routeConfig?: NonIndexRouteObject;
  children?: RouteObject[];
}

const lazy = (path: string) => {
  return async () => {
    const Component = await import(`../${path}`);
    return { Component: Component.default };
  };
};

export const privateRoutes: RouteInterface[] = [
  {
    path: '/',
    element: <Navigate to={LOGIN_LANDING_PAGE} />,
  },
  {
    path: '/account',
    lazy: lazy('pages/Account/Account'),
  },
  {
    path: '/admin',
    element: <Navigate to='/admin/projects' />,
    permission: {
      action: 'access',
      subject: 'Admin',
    },
  },
  {
    path: '/admin/tenants',
    showBreadcrumbs: true,
    lazy: lazy('pages/Admin/Tenants/Tenants'),
    permission: { action: 'access', subject: 'SuperTenant' },
  },
  {
    path: '/admin/tenants/:tenantId',
    lazy: lazy('pages/Admin/Tenants/Tenant/Tenant'),
    showBreadcrumbs: true,
    permission: {
      action: 'access',
      subject: 'SuperTenant',
    },
  },
  {
    path: '/admin/stores',
    lazy: lazy('pages/Admin/Stores/Stores'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'Store',
    },
  },
  {
    path: '/admin/stores/:storeId',
    lazy: lazy('pages/Admin/Stores/Store/Store'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'Store',
    },
  },
  {
    path: '/admin/projects',
    lazy: lazy('pages/Admin/Campaigns/Campaigns'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'Campaign',
    },
  },
  {
    path: '/admin/projects/:campaignId',
    lazy: lazy('pages/Admin/Campaigns/Campaign/Campaign'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'Campaign',
    },
  },
  {
    path: '/admin/brands',
    lazy: lazy('pages/Admin/Brands/Brands'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'Brand',
    },
  },
  {
    path: '/admin/users',
    lazy: lazy('pages/Admin/Users/Users'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'User',
    },
  },
  {
    path: '/admin/users/:userId',
    lazy: lazy('pages/Admin/Users/User/User'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'User',
    },
  },
  {
    path: '/admin/groups',
    lazy: lazy('pages/Admin/UserGroups/UserGroups'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'UserGroup',
    },
  },
  {
    path: '/admin/groups/:groupId',
    lazy: lazy('pages/Admin/UserGroups/UserGroup/UserGroup'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'UserGroup',
    },
  },
  {
    path: '/admin/tag-groups',
    lazy: lazy('pages/Admin/TagGroups/TagGroups'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'TagGroup',
    },
  },
  {
    path: '/admin/tag-groups/:tagGroupId',
    lazy: lazy('pages/Admin/TagGroups/TagGroup/TagGroup'),
    permission: { action: 'read', subject: 'TagGroup' },
  },
  {
    path: '/admin/pillars',
    lazy: lazy('pages/Admin/Pillars/Pillars'),
    permission: { action: 'read', subject: 'Pillar' },
  },
  {
    path: '/admin/bulletins',
    lazy: lazy('pages/Admin/Bulletins/Bulletins'),
    permission: { action: PermissionAction.Access, subject: 'SuperTenant' },
  },
  {
    path: '/admin/stepTemplates',
    lazy: lazy('pages/Admin/StepTemplates/StepTemplates'),
    showBreadcrumbs: true,
    permission: { action: 'read', subject: 'StepTemplate' },
  },
  {
    path: '/admin/stepTemplates/create',
    lazy: lazy('pages/Admin/StepTemplates/CreateStepTemplate'),
    permission: {
      action: 'create',
      subject: 'StepTemplate',
    },
  },
  {
    path: '/admin/stepTemplates/:stepTemplateId',
    lazy: lazy('pages/Admin/StepTemplates/StepTemplate/StepTemplate'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'StepTemplate',
    },
  },
  {
    path: '/admin/stepTemplates/edit/:stepTemplateId',
    lazy: lazy('pages/Admin/StepTemplates/UpdateStepTemplate'),
    permission: {
      action: 'update',
      subject: 'StepTemplate',
    },
  },
  {
    path: '/admin/media-categories',
    lazy: lazy('pages/Admin/MediaCategories/MediaCategories'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'MediaCategory',
    },
  },
  {
    path: '/admin/media-categories/:mediaCategoryId',
    lazy: lazy('pages/Admin/MediaCategories/MediaCategory/MediaCategory'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'MediaCategory',
    },
  },
  {
    path: '/projects',
    lazy: lazy('pages/User/Projects'),
  },
  {
    path: '/projects/:projectId/dashboard/:tabId?',
    shortcutPath: '/projects/dashboard',
    lazy: lazy('pages/User/Dashboard/Dashboard'),
    permission: {
      action: 'read',
      subject: 'AnalyticsDashboard',
    },
  },
  {
    path: '/projects/:projectId/reports/:tabId?',
    shortcutPath: '/projects/reports',
    lazy: lazy('pages/User/Reports/Reports'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'TemplateResponse',
    },
  },
  {
    path: '/projects/:projectId/reports/log/detail/:date?/:store?/:ids?',
    shortcutPath: '/projects/reports',
    lazy: lazy('pages/User/Reports/LogView/components/LogViewDetails/LogViewDetails'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'TemplateResponse',
    },
  },
  {
    path: AppPath.TemplateResponseView,
    lazy: lazy('pages/User/TemplateResponses/TemplateResponse/TemplateResponse'),
    permission: {
      action: 'read',
      subject: 'TemplateResponse',
    },
  },
  {
    path: AppPath.TemplateResponseCombined,
    lazy: lazy('pages/User/TemplateResponses/CombinedTemplateResponses/CombinedTemplateResponses'),
    permission: {
      action: 'read',
      subject: 'TemplateResponse',
    },
    children: [
      {
        index: true,
        lazy: lazy('pages/User/TemplateResponses/CombinedTemplateResponses/SummaryView'),
      },
      {
        path: appChildPaths[AppPath.TemplateResponseCombined].List,
        lazy: lazy(
          'pages/User/TemplateResponses/CombinedTemplateResponses/TemplateResponseListView'
        ),
      },
      {
        path: appChildPaths[AppPath.TemplateResponseCombined].StepGroupView,
        lazy: lazy('pages/User/TemplateResponses/CombinedTemplateResponses/StepGroupView'),
      },
      {
        path: appChildPaths[AppPath.TemplateResponseCombined].StepResponsesView,
        lazy: lazy('pages/User/TemplateResponses/CombinedTemplateResponses/StepResponsesView'),
      },
    ],
  },
  {
    path: AppPath.ActivityList,
    shortcutPath: '/projects/activities',
    lazy: lazy('pages/User/Activities/Activities'),
    permission: {
      action: 'read',
      subject: 'Activity',
    },
  },
  {
    path: AppPath.ActivityCreate,
    lazy: lazy('pages/User/Activities/ActivityWizard/CreateActivityWizard'),
    permission: {
      action: 'create',
      subject: 'Activity',
    },
  },
  {
    path: AppPath.ActivityCopy,
    lazy: lazy('pages/User/Activities/ActivityWizard/CreateActivityWizard'),
    permission: {
      action: 'create',
      subject: 'Activity',
    },
  },
  {
    path: AppPath.ActivityEdit,
    lazy: lazy('pages/User/Activities/ActivityWizard/UpdateActivityWizard'),
    permission: {
      action: 'update',
      subject: 'Activity',
    },
  },
  {
    path: AppPath.ActivityView,
    lazy: lazy('pages/User/Activities/Activity/Activity'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'Activity',
    },
  },
  {
    path: AppPath.ActivityAssignmentView,
    lazy: lazy('pages/User/Activities/ActivityAssignment/ActivityAssignment'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'Activity',
    },
  },
  {
    path: '/projects/:projectId/gallery',
    shortcutPath: '/projects/gallery',
    lazy: lazy('pages/User/Gallery/Gallery'),
    permission: {
      action: 'read',
      subject: 'Media',
    },
  },
  {
    path: '/projects/:projectId/docs',
    shortcutPath: '/projects/docs',
    lazy: lazy('pages/User/Documents/Documents'),
    permission: {
      action: 'read',
      subject: 'Documents',
    },
  },
  {
    path: 'projects/:projectId/dashboard/Pillar Scores/ScoreByPillarByStore',
    lazy: lazy('pages/User/Dashboard/PillarScores/ScoreByPillarByStore/ScoreByPillarByStorePage'),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'Campaign',
    },
  },
  {
    path: 'projects/:projectId/dashboard/Report Scores/ScoreByReportTypeByStore',
    lazy: lazy(
      'pages/User/Dashboard/ReportScores/ScoreByReportTypeByStore/ScoreByReportTypeByStorePage'
    ),
    showBreadcrumbs: true,
    permission: {
      action: 'read',
      subject: 'Campaign',
    },
  },
];

export const publicRoutes: RouteInterface[] = [
  {
    path: '/login',
    lazy: lazy('pages/Auth/Login/Login'),
  },
  {
    path: '/forgotPassword/:tenantId?/:email?',
    lazy: lazy('pages/Auth/ForgotPassword/ForgotPassword'),
  },
  {
    path: '/resetPassword/:email/:tenantId?',
    lazy: lazy('pages/Auth/ResetPassword/ResetPassword'),
  },
  {
    path: '/temporaryPassword/:email/:tenantId?',
    lazy: lazy('pages/Auth/TemporaryPassword/TemporaryPassword'),
  },
  {
    path: '/auth/callback',
    lazy: lazy('pages/Auth/Callback/Callback'),
  },
  {
    path: '/logout',
    lazy: lazy('pages/Auth/Logout/Logout'),
  },
  {
    path: '/content/licence_agreement',
    lazy: lazy('pages/Content/Licence'),
  },
  {
    path: '/content/privacy',
    lazy: lazy('pages/Content/Privacy'),
  },
  {
    path: '/content/feedback',
    lazy: lazy('pages/Content/Feedback'),
  },
  {
    path: '/session/redirect',
    lazy: lazy('pages/Session/RedirectPage'),
  },
];
