import React, { ReactElement } from 'react';
import { CircularProgress } from '@mui/material';
import styled from 'styled-components';

const LoadingContainer = styled.div`
  padding-top: 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const LoadingMessage = styled.div`
  margin-top: 1rem;
  font-size: 1rem;
`;

export interface LoadingInterface {
  message?: ReactElement | string;
}

const Loading = ({ message }: LoadingInterface) => (
  <LoadingContainer>
    <CircularProgress />
    <LoadingMessage>{message}</LoadingMessage>
  </LoadingContainer>
);

export default Loading;
