import { useEffect } from 'react';
import { gtag } from 'utils/analytics/dataLayer';
import { UserInterface } from 'context/auth.context';

export default function useUserAnalyticsIdsRegistration(user?: UserInterface) {
  useEffect(() => {
    if (user?.user.id) {
      gtag({ user_id: user.user.id });
    }

    if (user?.user.tenant?.id) {
      gtag({ tenant_id: user?.user.tenant?.id, tenant_name: user?.user.tenant?.name });
    }
  }, [user?.user.id, user?.user.tenant?.name, user?.user.tenant?.id]);
}
