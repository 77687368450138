import NotFound from 'pages/NotFound';
import React, { Component, ReactNode, ErrorInfo, PropsWithChildren } from 'react';

interface ErrorBoundaryState {
  hasError: boolean;
  error?: Error;
  errorInfo?: ErrorInfo;
}

// This is a class component because componentDidCatch and getDerivedStateFromError do not have direct equivalents in functional components
class ErrorBoundary extends Component<PropsWithChildren<{}>, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false };

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error(error, errorInfo);
    this.setState({ error, errorInfo });
  }

  render(): ReactNode {
    if (this.state.hasError) {
      return (
        <>
          <NotFound />
          {/* Uncomment below if you want to render more info for debugging */}
          {/* <details style={{ whiteSpace: 'pre-wrap' }}>
            {this.state.error && this.state.error.toString()}
            <br />
            {this.state?.errorInfo?.componentStack}
          </details> */}
        </>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
