import { createTheme, ThemeOptions } from '@mui/material/styles';

export const HEADER_SIZE_DESKTOP = '4rem';
export const HEADER_SIZE_MOBILE = '3.5rem';

declare module '@mui/material/styles' {
  // Add custom theme variables.
  interface Theme {
    colors: ThemeColorsInterface;
  }

  interface ThemeOptions {
    colors: ThemeColorsInterface;
    breakpoints: BreakpointInterface;
    standardPadding: string;
    borderColor: string;
  }

  interface Palette {
    brand: Palette['primary'];
    danger: Palette['primary'];
  }

  interface PaletteOptions {
    brand: PaletteOptions['primary'];
    danger: PaletteOptions['primary'];
  }
}

declare module '@mui/material/Button' {
  interface ButtonPropsColorOverrides {
    brand: true;
    danger: true;
  }
}

declare module '@mui/material/Chip' {
  interface ChipPropsVariantOverrides {
    filter: true;
  }
}

export type AlertTypeColors = {
  backgroundColor: string;
  borderColor: string;
  iconColor: string;
  textColor?: string;
  secondary300?: string;
};

type ThemeColorsInterface = {
  black: string;
  black60: string;
  darkBlack: string;
  white: string;
  offWhite: string;
  lightBlueGray: string;
  brandBlue: string;
  brandBlueSecondary: string;
  brandBlue30: string;
  blue300: string;
  filterBlueGray: string;
  commentLink: string;
  violet: string;
  magenta: string;
  muted: string;
  border: string;
  neutral800_new: string;
  //@deprecated - this neutral800 no longer matches the new neutral800 in figma, see neutral800_new
  neutral800: string;
  neutral700: string;
  neutral600: string;
  neutral550: string;
  neutral500: string;
  //@deprecated - this neutral400 no longer matches the new neutral400 in figma, see neutral400_new
  neutral400: string;
  neutral400_new: string;
  neutral300: string;
  neutral250: string;
  neutral200: string;
  burntOrange: string;
  lightGray: string;
  gray700: string;
  gray400: string;
  gray300: string;
  orange: string;
  disabled: string;
  alertRed: string;
  dropShadow: string;
  success900: string;
  success500: string;
  success400: string;
  success100: string;
  warning: string;
  warning200: string;
  error900: string;
  error300: string;
  error200: string;
  error100: string;
  info900: string;
  info300: string;
  info200: string;
  cancelled: string;
  subTableBackgroundColor: string;
  subTableRowChainBorderColor: string;
  borderColor: string;
  headerBackground: string;
  headerBackgroundHover: string;
  menuHover: string;
  tableRowHover: string;
  tableRowButtonHover: string;
  userMultitenantHighlight: string;
  alertColors: {
    warning: AlertTypeColors;
    success: AlertTypeColors;
    info: AlertTypeColors;
    error: AlertTypeColors;
  };
  status: {
    cancelled: string;
    completed: string;
    inProgress: string;
    overdue: string;
    paused: string;
    scheduled: string;
  };
  graph: string[];
  filterChip: string;
  filterChipIcon: string;
  filterChipIconHover: string;
  filterShrink: string;
  primaryHover: string;
  sidebarHover: string;
  sidebarActive: string;
  sidebarOpen: string;
  ourCommentLight: string;
  commentScrollbarDark: string;
  commentBottomBorderLight: string;
  commentReactionOthers: string;
  commentReactionOurs: string;
  commentReactionName: string;
  commentReactionSelected: string;
  asideBoxBackground: string;
  lightbox: {
    backgroundColor: string;
  };
};

const colors: ThemeColorsInterface = {
  black: '#0E0C19',
  black60: 'rgba(14, 12, 25, .6)', //#0e0c19 60% opacity
  darkBlack: '#060606',
  white: '#fff',
  offWhite: '#FAFAFA',
  brandBlue: '#3366ff',
  brandBlueSecondary: '#2260DC',
  brandBlue30: 'rgba(51, 102, 255,.3)',
  lightBlueGray: '#F6F9FF',
  blue300: '#B5C8FF',
  filterBlueGray: '#EAF0F8',
  commentLink: '#8abdff',
  violet: '#7872ff',
  magenta: '#DD7BEB',
  muted: '#5e6a82',
  border: '#E4E4E4',
  neutral800_new: '#354461',
  neutral800: '#0E0C19',
  neutral700: '#242230',
  neutral600: '#403E4F',
  neutral550: '#747780',
  neutral500: '#646271',
  neutral400: '#edeff7',
  neutral400_new: '#908E9A',
  neutral300: '#DDDCE5',
  neutral250: '#F7F9FC',
  neutral200: '#F5F4F8',
  burntOrange: '#B85308',
  lightGray: '#a7a9b0',
  gray700: '#101113',
  gray400: '#66676E',
  gray300: '#8A8B91',
  orange: '#F68936',
  disabled: '#403E4F',
  dropShadow: '#646271',
  success900: '#33CC59',
  success400: '#5CD67A',
  success100: '#D9F6E0',
  success500: '#29A347',
  warning: '#FFC300',
  warning200: '#FEE89F',
  alertRed: '#CD2026',
  error900: '#E2122B',
  error300: '#F47181',
  error200: '#FA9BA7',
  error100: '#fffafa',
  info900: '#130E81',
  info300: '#C4C2FA',
  info200: '#DDDCFF',
  cancelled: '#b0abcc',
  subTableBackgroundColor: '#f5f4f8',
  subTableRowChainBorderColor: '#908e9a',
  borderColor: '#CACACA',
  menuHover: '#595767',
  tableRowHover: '#F1F5FD',
  tableRowButtonHover: '#E5EBF7',
  userMultitenantHighlight: '#A9C1FC',
  headerBackground: '#100F18',
  headerBackgroundHover: '#2D3444',
  alertColors: {
    warning: {
      backgroundColor: '#FEE89F',
      borderColor: '#997500',
      iconColor: '#514009',
      textColor: '#000000',
    },
    error: {
      backgroundColor: '#FFE3E7',
      borderColor: '#BD0F24',
      iconColor: '#8E0B1B',
      textColor: '#000000',
      secondary300: '#FC897D',
    },
    success: {
      backgroundColor: '#B0F7C1',
      borderColor: '#1F7A35',
      iconColor: '#145C25',
      textColor: '#000000',
    },
    info: {
      backgroundColor: '#EFEEFF',
      borderColor: '#4F49DF',
      iconColor: '#2F28C2',
      textColor: '#000000',
    },
  },
  status: {
    cancelled: '#f0e9e8',
    completed: '#b0f7c1',
    inProgress: '#FEE89F',
    overdue: '#ffb8c1',
    paused: '#E5EBEE',
    scheduled: '#DDDCFF',
  },
  graph: ['#FA9E8C', '#6551FF', '#135E59', '#229488', '#35D4BF'],
  filterChip: '#EAEFFF',
  filterChipIcon: '#8590B1',
  filterChipIconHover: '#6A738D',
  filterShrink: '#5E779B',
  primaryHover: '#2250DC',
  sidebarHover: '#EFF2F8',
  sidebarActive: '#B8C9FB',
  sidebarOpen: '#E3EBFE',
  ourCommentLight: '#F2F5FB',
  commentScrollbarDark: '#6e6c7a',
  commentBottomBorderLight: '#edeff7',
  commentReactionOthers: '#DDDCE5',
  commentReactionOurs: '#8D9DBE',
  commentReactionName: '#D8D9DB',
  commentReactionSelected: '#8D9DBE',
  asideBoxBackground: '#F6F8FC',
  lightbox: {
    backgroundColor: 'rgba(0, 0, 0, 0.85)',
  },
};

interface BreakpointInterface {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
}

export const breakpoints: BreakpointInterface = {
  xs: 0,
  sm: 600,
  md: 900,
  lg: 1200,
  xl: 1536,
};

export const themeOptions: ThemeOptions = {
  // Custom themes
  colors,
  breakpoints,
  standardPadding: '1rem',
  borderColor: colors.borderColor,
  // color palette
  palette: {
    primary: {
      main: colors.brandBlue,
    },
    text: {
      primary: colors.black,
      disabled: colors.disabled,
    },
    background: {
      default: colors.offWhite,
    },
    success: {
      main: colors.success900,
      contrastText: colors.black,
    },
    warning: {
      main: colors.warning,
      contrastText: colors.black,
    },
    error: {
      main: colors.error900,
      contrastText: colors.white,
    },
    danger: {
      main: colors.error300,
      contrastText: colors.white,
    },
    brand: {
      main: colors.brandBlue,
      contrastText: colors.white,
    },
  },
  // typography
  typography: {
    fontFamily: 'general-sans',
    fontSize: 16,
    h1: {
      fontSize: '3rem',
      fontWeight: 'regular',
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 400,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 300,
    },
    subtitle1: {
      fontSize: '1.25rem',
      fontWeight: 'regular',
    },
    subtitle2: {
      fontSize: '1.25rem',
      fontWeight: 'medium',
    },
    body1: {
      fontSize: '1rem',
      fontWeight: 'regular',
    },
    body2: {
      fontSize: '1rem',
      fontWeight: 'medium',
    },
    button: {
      fontSize: '1rem',
      fontWeight: 'medium',
      color: colors.black,
      borderBlockColor: colors.black,
    },
  },
  // components ofc
  components: {
    MuiButton: {
      defaultProps: {
        variant: 'outlined',
      },
      styleOverrides: {
        root: {
          textTransform: 'none',
          borderRadius: '0.625rem',
          fontWeight: 'medium',
          border: 'none',
          padding: '0.25rem 1.5rem',
          '& a': {
            color: colors.black,
          },
          ':hover': {
            color: colors.white,
            backgroundColor: colors.black,
            borderColor: colors.black,
            '& a': {
              color: colors.white,
            },
          },
        },
        outlined: {
          border: ' solid 0.0625rem',
        },
        outlinedPrimary: {
          color: colors.black,
          '&:hover': {
            svg: {
              path: {
                fill: colors.white,
              },
            },
          },
        },
        containedPrimary: {
          color: colors.white,
          border: 'solid 0.0625rem',
          borderColor: colors.brandBlue,
          backgroundColor: colors.brandBlue,
          fontWeight: 500,
          letterSpacing: '0.02rem', // 0.32px
          padding: '.4375rem 1.125rem .4375rem 1.125rem',
          ':hover': {
            color: colors.white,
            backgroundColor: colors.primaryHover,
            borderColor: colors.primaryHover,
          },
          '&:disabled': {
            backgroundColor: colors.neutral300,
            borderColor: colors.neutral300,
            color: colors.white,
          },
          '&.danger': {
            borderColor: colors.alertRed,
            backgroundColor: colors.alertRed,
            ':hover': {
              color: colors.alertRed,
              borderColor: colors.alertRed,
              backgroundColor: colors.white,
            },
          },
        },
        containedSecondary: {
          color: colors.brandBlue,
          borderRadius: '0.625rem', // 10px
          backgroundColor: 'transparent',
          border: 'none',
          boxShadow: 'none',
          fontWeight: '500',
          letterSpacing: '0.02rem', // 0.32px
          marginLeft: '0.75rem',
          ':hover': {
            color: colors.brandBlue,
            border: 'none',
            backgroundColor: 'transparent',
            boxShadow: 'none',
          },
        },
        containedSuccess: {
          color: colors.white,
          backgroundColor: colors.success400,
        },
        outlinedSecondary: {
          color: colors.brandBlue,
          border: 'solid 0.0625rem',
          borderColor: colors.brandBlue,
          fontSize: '1rem',
          fontWeight: 500,
          letterSpacing: '0.02rem',
          padding: '0.4375rem 1.125rem',
          ':hover': {
            color: colors.brandBlueSecondary,
            backgroundColor: colors.lightBlueGray,
            borderColor: colors.brandBlueSecondary,
          },
          '& a:hover': {
            color: colors.black,
          },
        },
        text: {
          borderBottom: `0.0625rem solid ${colors.black}`,
          borderRadius: 0,
          margin: '0 1.25rem',
          padding: '0.25rem 0',
          '&:hover': {
            color: colors.black,
            backgroundColor: 'inherit',
          },
          '&.table-dropdown': {
            margin: 0,
            padding: '0.5rem 0',
            color: colors.black,
            '&:hover': {
              backgroundColor: colors.neutral300,
            },
          },
          '&.list-dropdown': {
            margin: 0,
            padding: '0.5rem 0',
            color: colors.black,
          },
        },
      },
    },
    MuiIcon: {
      styleOverrides: {
        root: {
          backgroundColor: colors.brandBlue,
          ':hover': {
            backgroundColor: colors.brandBlue,
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        root: {
          ':hover': {
            color: colors.brandBlue,
          },
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            backgroundColor: colors.blue300,
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          '.MuiDateCalendar-root': {
            backgroundColor: colors.offWhite,

            '.MuiPickersCalendarHeader-labelContainer': {
              fontSize: '1.15rem',
              pointerEvents: 'none',
              position: 'absolute',
              right: '50%',
              transform: 'translateX(50%)',
              '.MuiPickersCalendarHeader-switchViewButton': {
                display: 'none',
              },
            },

            '.MuiPickersArrowSwitcher-root': {
              '.MuiButtonBase-root': {
                position: 'absolute',
                top: '0.5rem',
                '&:first-child': {
                  left: '2rem',
                },
                '&:nth-child(3)': {
                  right: '2rem',
                },
              },
            },

            '.MuiDayCalendar-header': {
              '.MuiTypography-root': {
                fontSize: '1rem',
                fontWeight: 400,
                color: colors.black,
              },
            },

            '.MuiButtonBase-root': {
              '&:focus, &:hover': {
                backgroundColor: 'inherit',
              },
            },

            '.MuiSvgIcon-root': {
              color: colors.brandBlue,
              fontSize: '2rem',
            },

            '.MuiPickersDay-root': {
              borderRadius: '0.125rem',
              backgroundColor: 'inherit',
              fontSize: '1rem',
              fontWeight: 300,
              color: colors.neutral600,
              '&:hover': {
                color: colors.subTableRowChainBorderColor,
              },
            },

            '.MuiPickersDay-today': {
              backgroundColor: colors.blue300,
              borderColor: colors.blue300,
            },

            '.Mui-selected': {
              border: `0.025rem solid ${colors.neutral700}`,
              backgroundColor: colors.neutral200,
              color: colors.neutral600,
              fontWeight: 300,
              '&:focus': {
                border: `0.025rem solid ${colors.neutral700}`,
                backgroundColor: colors.neutral200,
                color: colors.neutral600,
                fontWeight: 300,
              },
            },
            '.Mui-disabled': {
              color: `${colors.lightGray} !important`,
            },
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '&.filter': {
            backgroundColor: colors.white,
            fontWeight: 500,
            '& .MuiOutlinedInput-root': {
              backgroundColor: colors.white,
              '&.Mui-focused fieldset': {
                border: `1px solid ${colors.neutral600}`,
              },
            },
            '& .MuiInputLabel-shrink': {
              color: colors.filterShrink,
            },
          },
          '&.password-input': {
            '& .MuiInputAdornment-root': {
              width: '3rem',
              '& .MuiIconButton-root': {
                fontSize: '0.875rem', // 14px
                color: colors.brandBlue,
                fontWeight: 500,
              },
            },
          },
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: colors.black,
        },
        asterisk: {
          color: '#db3131',
          '&$error': {
            color: '#db3131',
          },
        },
      },
    },
    MuiInput: {
      styleOverrides: {
        root: {},
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: colors.black,
        },
      },
    },
    MuiAutocomplete: {
      styleOverrides: {
        root: {},
        paper: ({ ownerState }) => ({
          ...(ownerState.className === 'filter' && {
            '& ul': {
              '& li': {
                backgroundColor: colors.white,
                fontWeight: 500,
              },
            },
          }),
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {},
      },
      variants: [
        {
          props: { variant: 'filter' },
          style: {
            backgroundColor: colors.filterChip,
            fontWeight: 400,
            fontSize: '1rem',
            borderRadius: '0.5rem',
            '&.MuiChip-filter .MuiChip-deleteIcon': {
              color: colors.filterChipIcon,
              background: 'radial-gradient(white 7px, transparent 2px)',
              borderRadius: '50%',
              '&:hover': {
                color: colors.filterChipIconHover,
              },
            },
          },
        },
      ],
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          borderRadius: '0.25rem',
          boxShadow:
            '0 0.25rem 0.5rem 0.1875rem rgba(0, 0, 0, 0.15), 0 0.0625rem 0.1875rem 0 rgba(0, 0, 0, 0.30)',
          color: colors.neutral700,
          fontWeight: 500,
          lineHeight: '1.25rem',
          letterSpacing: '0.015625rem',
          gap: '0.5rem',
          marginTop: '3.5rem',
          '& .MuiAlert-icon': {
            marginRight: 0,
            width: '1.25rem',
            height: '1.25rem',
          },
        },
        filledSuccess: {
          backgroundColor: colors.alertColors.success.backgroundColor,
        },
        filledError: {
          backgroundColor: colors.alertColors.error.backgroundColor,
        },
        filledWarning: {
          backgroundColor: colors.alertColors.warning.backgroundColor,
        },
      },
    },
    MuiDialog: {
      styleOverrides: {
        root: {
          '.MuiPaper-root': {
            '@media(min-width: 600px)': {
              minWidth: '35rem',
            },
            '@media(max-width: 600px)': {
              maxWidth: '100vw',
            },
            borderRadius: '0.5rem',
          },
          '& .titleContainer': {
            h5: {
              textAlign: 'left',
              color: colors.neutral700,
              fontSize: '2rem',
              fontWeight: 400,
              lineHeight: '2.375rem', // 38px,
              marginLeft: '-0.075rem',
            },
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-end',
            flexShrink: 0,
            marginTop: '0.5rem',
            marginBottom: '2rem',
          },
          '& .descriptionContainer': {
            color: colors.black,
            fontSize: '1.125rem', // 18px
            fontWeight: 400,
            marginTop: '-1.75rem', // Description is optional, we need to offset the title margin if it's present
            marginBottom: '0.75rem',
            textAlign: 'left',
          },
          'label:not(.searchbar)': {
            color: colors.black,
            fontSize: '1rem',
            fontWeight: 500,
            margin: 0,
          },
          '& .MuiInputBase-root': {
            backgroundColor: colors.white,
          },
          '& .MuiAutocompleteInput-root': {
            backgroundColor: colors.white,
          },
          '& .MuiFormControl-root': {
            '&.media-comment-input': {
              display: 'block',
              marginTop: 0,
              marginBottom: 0,
              '& .MuiInputBase-root': {
                backgroundColor: 'inherit',
              },
              '& .MuiInputBase-multiline': {
                marginTop: 0,
              },
            },
            display: 'flex',
            marginTop: '0.5rem',
            marginBottom: '1.25rem',
          },
          '& .MuiInputBase-multiline': {
            marginTop: '-1.25rem',
          },
        },
      },
    },
    MuiPopover: {
      styleOverrides: {
        root: {
          '&.table-dropdown': {
            marginTop: '1rem',
            '& ul': {
              padding: 0,
            },
          },
          '&.list-dropdown': {
            marginTop: '1rem',
            '& ul': {
              padding: 0,
            },
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          '&.table-dropdown': {
            width: '13rem',
            backgroundColor: colors.white,
            padding: '1rem 0.5rem',
            borderBottom: `1px solid ${colors.border}`,
            '&:hover': {
              backgroundColor: colors.lightBlueGray,
              fontWeight: 500,
            },
          },
          '&.list-dropdown': {
            backgroundColor: colors.white,
            padding: '1rem 0.5rem 1rem 0.4rem',
            '&:hover': {
              backgroundColor: colors.lightBlueGray,
              fontWeight: 500,
            },
            svg: {
              marginBottom: '-0.5rem',
            },
            '&.MuiButtonBase-root': {
              fontWeight: 500,
            },
          },
        },
      },
    },
  },
};

const theme = createTheme(themeOptions);

export default theme;
