import {
  ApolloClient,
  createHttpLink,
  InMemoryCache,
  Reference,
  StoreObject,
} from '@apollo/client';
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common';
import { setContext } from '@apollo/client/link/context';
import { UserInterface } from 'context/auth.context';
import { FieldMergeFunction } from '@apollo/client/cache/inmemory/policies';
import { GetTemplateResponseLogDataQuery } from '__generated__/graphql';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API,
});

const authLink = setContext((_, { headers }) => {
  let token = localStorage.getItem('restore.user');
  let tenantId;

  if (token) {
    const userJson: UserInterface = JSON.parse(token);
    token = userJson.tokens.id_token ?? userJson.tokens.access_token ?? null;
    tenantId = userJson.user.tenant?.id;
  }

  return {
    headers: {
      ...headers,
      Authorization: token ? `Bearer ${token}` : '',
      Tenant_id: tenantId,
    },
  };
});

// The ReadFieldFunction type is exported, but the MergeObjectsFunction type is not, hence copying the type here
const mergeExistingAndIncoming = ({
  existing,
  incoming,
  readField,
  mergeObjects,
}: {
  existing: any[];
  incoming: any[];
  readField: ReadFieldFunction;
  mergeObjects: <T extends StoreObject | Reference>(existing: T, incoming: T) => T;
}): any[] => {
  const merged: any[] = incoming ? incoming.slice(0) : [];
  const recordToIndex: Record<string, number> = Object.create(null);

  if (incoming) {
    incoming.forEach((record, index) => {
      const recordId = readField<string>('id', record);
      if (recordId) recordToIndex[recordId] = index;
    });
  }

  if (existing) {
    existing.forEach((record) => {
      const recordId = readField<string>('id', record);
      if (recordId) {
        const index = recordToIndex[recordId];
        // This record exists within the incoming array -- combine the records
        if (typeof index === 'number') {
          merged[index] = mergeObjects(merged[index], record);
        }
        // If the record does not exist within the incoming array, do nothing; it was removed from the list and should not be re-added
      }
    });
  }

  return merged;
};

const makePaginationMerge =
  (resultsField: string): FieldMergeFunction =>
  (existing, incoming, { readField }) => {
    const incomingArray = (incoming?.[resultsField] ?? []).slice();
    const incomingIds = incomingArray.map((x: StoreObject | Reference | undefined) =>
      readField('id', x)
    );
    const filteredExistingArray = (existing?.[resultsField] ?? []).map(
      (x: StoreObject | Reference | undefined) => {
        if (incomingIds.includes(readField('id', x))) {
          const index = incomingArray.findIndex(
            (incomingItem: Reference | StoreObject | undefined) =>
              readField('id', incomingItem) === readField('id', x)
          );
          if (index !== -1) {
            const value = incomingArray[index];
            incomingArray.splice(index, 1);
            return value;
          } else {
            return x;
          }
        }

        return x;
      }
    );

    return {
      ...incoming,
      [resultsField]: [...filteredExistingArray, ...incomingArray],
    };
  };

export const apolloClient = new ApolloClient({
  connectToDevTools: process.env.REACT_APP_ENVIRONMENT === 'development',
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          mediaPaginated: {
            keyArgs: ['input', ['tenantId', 'filters', 'sortBy']],
            merge: makePaginationMerge('stepResponseMediaItems'),
          },
          templateResponseLogData: {
            // Everything except dateRange here since that's used for pagination for Log view.
            keyArgs: ['input', ['tenantId', 'filters']],
            merge: (
              existing: GetTemplateResponseLogDataQuery['templateResponseLogData'] | undefined,
              incoming: GetTemplateResponseLogDataQuery['templateResponseLogData']
            ): GetTemplateResponseLogDataQuery['templateResponseLogData'] => {
              const existingStats = (existing?.stats ?? []).filter(
                (prevStat) =>
                  !incoming.stats.some(
                    (newStat) =>
                      newStat.storeId === prevStat.storeId &&
                      newStat.dateFor === prevStat.dateFor &&
                      newStat.status === prevStat.status
                  )
              );
              const dupedStats = (existing?.stats ?? []).filter(
                (prev) => !existingStats.includes(prev)
              );
              const dupedCount = dupedStats.reduce((sum, dupeStat) => {
                return sum + dupeStat.responseCount;
              }, 0);

              return {
                ...incoming,
                filteredCount:
                  (existing?.filteredCount ?? 0) + (incoming.filteredCount - dupedCount),
                stats: [...existingStats, ...incoming.stats],
              };
            },
          },
        },
      },
      UserGroup: {
        fields: {
          permissions: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
        },
      },
      Campaign: {
        fields: {
          stores: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          tags: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          users: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
        },
      },
      User: {
        fields: {
          tags: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          userGroups: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          brands: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
        },
      },
      Activity: {
        fields: {
          tags: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          activityAssignments: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          assignedUsers: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          ccRecipients: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          steps: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          media: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
        },
      },
      Question: {
        fields: {
          questionChoices: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
        },
      },
      TagGroup: {
        fields: {
          tags: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
        },
      },
      Tenant: {
        fields: {
          userTagGroups: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          storeTagGroups: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
        },
      },
      StepResponse: {
        fields: {
          stepResponseMedia: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
        },
      },
      Store: {
        fields: {
          brands: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          users: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          storeManagerUsers: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
          tags: {
            merge(existing: any[], incoming: any[], { readField, mergeObjects }) {
              return mergeExistingAndIncoming({ existing, incoming, readField, mergeObjects });
            },
          },
        },
      },
    },
  }),
});
