import React, { useEffect } from 'react';
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  RouteObject,
  NonIndexRouteObject,
} from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { AuthContextProvider } from 'context/auth.context';
import theme from 'theme';
import { ClientContextProvider } from 'context/client.context';
import { SnackbarContextProvider } from 'context/snackbar.context';
import { privateRoutes, publicRoutes } from 'router/routes';
import PrivateRoute from 'router/PrivateRoute';
import CustomSnackbar from 'components/Snackbar/CustomSnackbar';
import ErrorBoundary from 'ErrorBoundary';
import NotFound from 'pages/NotFound';

const router = createBrowserRouter([
  {
    Component: Layout,
    ErrorBoundary: ErrorBoundary,
    children: [
      ...publicRoutes.map(
        (route): RouteObject => ({
          path: route.path,
          lazy: route.lazy,
          ...route.routeConfig,
        })
      ),
      {
        Component: PrivateRoute,
        children: [
          ...privateRoutes.map((route): NonIndexRouteObject => {
            const routeObject: NonIndexRouteObject = {
              ...route.routeConfig,
              path: route.path,
              lazy: route.lazy,
              children: route.children,
            };
            if (route.element) routeObject.element = route.element;
            return routeObject;
          }),
        ],
      },
      {
        path: '*',
        element: <NotFound />,
      },
    ],
  },
]);

function Layout() {
  return (
    <AuthContextProvider>
      <ThemeProvider theme={theme}>
        <SnackbarContextProvider>
          {/* todo: move DashboardContextProvider provider */}
          <ClientContextProvider>
            <Outlet />
            <CustomSnackbar />
          </ClientContextProvider>
        </SnackbarContextProvider>
      </ThemeProvider>
    </AuthContextProvider>
  );
}

function App() {
  useEffect(() => {
    document.title = process.env.TITLE_TAG ?? 'ReStore for Retail v2';
  }, []);

  return (
    <ErrorBoundary>
      <RouterProvider router={router} />
    </ErrorBoundary>
  );
}

export default App;
