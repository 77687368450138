import React, { useMemo } from 'react';
import { Outlet, useLocation, Navigate } from 'react-router-dom';
import { useAuthContext } from 'context/auth.context';
import { LOGIN_LANDING_PAGE } from 'const/routes';
import { useSnackbarContext } from 'context/snackbar.context';
import { privateRoutes } from './routes';
import Loading from '../components/Messages/Loading/Loading';

function PrivateRoute() {
  const { loading, isSessionActive, hasPermission } = useAuthContext();
  const { displaySnackbarMessage } = useSnackbarContext();
  const location = useLocation();

  const permissible = useMemo(() => {
    const permission = privateRoutes.find((route) => route.path === location.pathname)?.permission;
    return (
      !permission || hasPermission(permission.action, permission.subject, permission?.conditions)
    );
  }, [location.pathname, hasPermission]);

  if (loading) {
    return <Loading />;
  } else if (!isSessionActive()) {
    return <Navigate to={'/login'} />;
  } else if (!permissible) {
    displaySnackbarMessage('Invalid Permissions', 'error');
    return <Navigate to={LOGIN_LANDING_PAGE} />;
  }

  return <Outlet />;
}

export default PrivateRoute;
